import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  HalvedContent,
  Button,
  SectionHead,
  ContentBox,
  Grid
} from 'src/components';
import { Card, CardHeader } from 'src/components/Cards';

import content from 'src/content/index';

const IndexPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisual.headline,
      paragraph: content.headvisual.paragraph,
      position: "75-top"
    }}
  >
    <section>
      <SectionHead headline={content.loesungen.headline} centered>
        <p>{content.loesungen.paragraph}</p>
      </SectionHead>
      <Grid>
        {content.loesungen.cards.map((card, index) => (
          <Card key={index} href={card.href}>
            <CardHeader headline={card.headline} centered smallFontSize>
              <card.icon />
            </CardHeader>
          </Card>
        ))}
      </Grid>
    </section>
    <section className="bg-light-blue">
      <SectionHead headline={content.dienstleistungen.headline} centered>
        <p>{content.dienstleistungen.paragraph}</p>
      </SectionHead>
      {content.dienstleistungen.entries.map((entry, index) => (
        <HalvedContent
          key={index}
          align="center"
          swapped={(index + 1) % 2 !== 0}
        >
          <BackgroundImage
            fluid={data[`halvedContent${index + 1}`].childImageSharp.fluid}
          />
          <ContentBox background="white" headline={entry.headline} wider>
            <p className="whitespace-pre-wrap">{entry.paragraph}</p>
            <Button href={entry.button.href} variant="gray-blue">
              {entry.button.text}
            </Button>
          </ContentBox>
        </HalvedContent>
      ))}
    </section>
    <section>
      <SectionHead headline={content.services.headline} centered />
      <Grid>
        {content.services.cards.map((card, index) => (
          <Card key={index} href={card.href}>
            <CardHeader headline={card.headline} centered smallFontSize>
              <card.icon />
            </CardHeader>
          </Card>
        ))}
      </Grid>
    </section>
    <section>
      <HalvedContent align="center" swapped>
        <BackgroundImage
          fluid={data.womanSittingAndHoldingATablet.childImageSharp.fluid}
        />
        <ContentBox
          background="light-salmon"
          headline={content.newsletter.headline}
        >
          <p>{content.newsletter.paragraph}</p>
          <Button href={content.newsletter.button.href} variant="gray-blue">
            {content.newsletter.button.text}
          </Button>
        </ContentBox>
      </HalvedContent>
    </section>
  </MainLayout>
);

export default IndexPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "nv_man-sitting-on-a-chair-and-laughing-width.jpg" }
    ) {
      ...image
    }
    halvedContent1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "nv_woman-sitting-and-holding-a-mobile-phone.jpg" }
    ) {
      ...image
    }
    halvedContent2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "nv_man-standing-and-holding-a-tablet.jpg" }
    ) {
      ...image
    }
    halvedContent3: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "nv_man-sitting-and-laughing.jpg" }
    ) {
      ...image
    }
    womanSittingAndHoldingATablet: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "nv_woman-sitting-and-holding-a-tablet.jpg" }
    ) {
      ...image
    }
  }
`;
