import EmployeeIcon from 'src/assets/icons/employee.svg';
import PeopleIcon from 'src/assets/icons/people.svg';
import CompanyIcon from 'src/assets/icons/company.svg';

import MagazineIcon from 'src/assets/icons/magazine.svg';
import FinancialAnalyticsIcon from 'src/assets/icons/financial-analytics.svg';
import AgreementIcon from 'src/assets/icons/agreement.svg';
import EditFileIcon from 'src/assets/icons/edit-file.svg';

const content = {
  title: 'Machen Sie Einkommensabsicherung zur Chefsache',
  headvisual: {
    headline: 'Machen Sie Einkommens-<br>absicherung zur Chefsache',
    paragraph:
      'Ein attraktiver Arbeitgeber? Wer möchte das nicht sein in Zeiten des Fachkräftemangels! Heben Sie sich mit wertvollen Zusatzleistungen vom Arbeitsmarkt ab, z. B. mit einer Berufsunfähigkeitsversicherung im Rahmen der betrieblichen Altersversorgung (bAV).'
  },
  loesungen: {
    headline: 'Unsere Lösungen auf einen Blick',
    paragraph:
      'Schützen was Ihnen wichtig ist – wir bieten Ihnen individuelle Absicherungen',
    cards: [
      {
        href: '/unsere-loesungen/absicherung-fuer-leitende-angestellte',
        icon: EmployeeIcon,
        headline: 'Absicherung für leitende Angestellte'
      },
      {
        href: '/unsere-loesungen/absicherung-fuer-mitarbeiter',
        icon: PeopleIcon,
        headline: 'Absicherung für Mitarbeiter'
      },
      {
        href: '/unsere-loesungen/absicherung-fuer-unternehmen',
        icon: CompanyIcon,
        headline: 'Absicherung für Unternehmen'
      }
    ]
  },
  dienstleistungen: {
    headline: 'Unsere Dienstleistungen',
    paragraph:
      'Lassen Sie sich durch unsere Vielzahl an fachkundigen Dienstleistungen von unserer Kompetenz überzeugen.',
    entries: [
      {
        headline: 'bAV-Beratung und Betreuung',
        paragraph:
          'Die Versorgungsordnung – als Basis für die betriebliche Altersversorgung. Rechtssicherheit. Einfach. Digital.',
        button: {
          href: '/unsere-dienstleistungen/bav-beratung-und-betreuung',
          text: 'Mehr erfahren'
        }
      },
      {
        headline: 'Versicherungsmathematische Dienstleistungen',
        paragraph:
          'Sie benötigen einen Bilanzausweis für unmittelbare Pensionsverpflichtungen? \n - Wir können helfen. Bei uns erhalten Sie das passende versicherungsmathematische Gutachten für Ihre Direktzusagen.',
        button: {
          href:
            '/unsere-dienstleistungen/versicherungsmathematische-dienstleistungen',
          text: 'Mehr erfahren'
        }
      },
      {
        headline: 'Personaldienstleistungen',
        paragraph:
          'Jedes Unternehmen erstellt Lohn- und Gehaltsabrechnungen. Dazu gehört viel administrativer Aufwand und es werden Mitarbeiterkapazitäten gebunden. Machen Sie es sich einfach und wählen Sie eines unserer Dienstleistungsmodelle rund ums Personal.',
        button: {
          href: '/unsere-dienstleistungen/personaldienstleistungen',
          text: 'Mehr erfahren'
        }
      }
    ]
  },
  services: {
    headline: 'Weitere Services',
    cards: [
      {
        href: '/aktuelles/ratgeber',
        icon: MagazineIcon,
        headline: 'Ratgeber'
      },
      {
        href: '/tools-und-services',
        icon: FinancialAnalyticsIcon,
        headline: 'Online-Rechner'
      },
      {
        href: '/mediathek',
        icon: AgreementIcon,
        headline: 'Mediathek'
      },
      {
        href: '/aktuelles/newsroom',
        icon: EditFileIcon,
        headline: 'Newsroom'
      }
    ]
  },
  newsletter: {
    headline: 'bAV Newsletter',
    paragraph: 'Mit dem bAV-Newsletter immer auf dem neuesten Stand sein. ',
    button: {
      href: '/aktuelles/newsletter',
      text: 'Jetzt anmelden'
    }
  }
};

export default content;
